import React from "react";
import {Link} from "gatsby";
import {Row, Col, Container} from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";

import {FaUserShield, FaRegHandshake, FaUserSlash, FaHandHoldingUsd, FaGlobe, FaPowerOff} from "react-icons/fa";
import SuccessStories from "../../sections/camelo/SuccessStories";
import CTA from "../../sections/camelo/features/CTA";

const LegalPage = () => {

  return (
    <>
      <PageWrapper>
        <SEO title="Customer Stories"></SEO>

        <SuccessStories />

        <div className="border-top">
          <CTA />
        </div>

      </PageWrapper >
    </>
  );
};
export default LegalPage;
